import React, { FC } from 'react';
import styled from 'styled-components';
import Particles from 'react-tsparticles';
import { CREATE_CARD_LINK, SLACK_INSTALL_LINK } from '../constants/links';
import LinkButton from '../components/LinkButton';

const Container = styled.div`
  margin-bottom: 84px;

  position: relative;

  #tsparticles {
    padding: 0 0 64px;
    margin: 0 auto;
    width: 100%;
    max-width: 500px;
    z-index: 0;
  }

  canvas {
    position: relative !important;
    width: 100% !important;
    height: 400px !important;
  }

  @media ${(props) => props.theme.breakpoints.tabletP} {
    margin-bottom: 222px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    margin-bottom: 222px;
  }
`;

const Body = styled.div`
  height: 100%;
  bottom: 0;
  padding: 64px 10px 24px 10px;
  position: absolute;
  width: 100%;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    height: 50%;
    padding: 48px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    height: 50%;
    padding: 0 128px;
  }
`;

const Header = styled.p`
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  margin: 8px auto 16px auto;
  text-align: center;
  max-width: 490px;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    font-size: 44px;
    line-height: 55px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 64px;
    line-height: 64px;
  }
`;

const Paragraph = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin: 8px 0;
  text-align: center;
  color: ${(props) => props.theme.colors.textColorCopy};

  @media ${(props) => props.theme.breakpoints.tabletP} {
    font-size: 16px;
    line-height: 22px;
    margin: 8px 0 16px 0;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 18px;
    line-height: 26px;
    margin: 8px 0 16px 0;
  }
`;

const JoinButton = styled(LinkButton)`
  margin: 16px auto;
  display: block;
  position: relative;
  width: 100%;
  max-width: 300px;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    max-width: 180px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    max-width: 248px;
  }

  &:hover {
    background-color: #e5a619;
  }
`;

const Actions = styled.div`
  margin: 16px auto;
  display: block;
  position: relative;
  width: 100%;
  max-width: 300px;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    max-width: 180px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    max-width: 190px;
  }
`;

const GroupGiftLink = styled(LinkButton)`
  width: 100%;
  height: 48px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 10px;
  color: #1d1c1d;
  @media ${(props) => props.theme.breakpoints.tabletP} {
    max-width: 184px;
  }
  @media ${(props) => props.theme.breakpoints.laptop} {
    max-width: 190px;
  }
  &:hover {
    background-color: #e5a619;
  }
`;

const COLOUR_MAP = [
  {
    title: 'Blue',
    hex: '#5ED9F9',
  },
  {
    title: 'Orange',
    hex: '#FAC079',
  },
  {
    title: 'Green',
    hex: '#A2FE92',
  },
  {
    title: 'Pink',
    hex: '#FD79F8',
  },
  {
    title: 'Purple',
    hex: '#BE8EF7',
  },
  {
    title: 'Red',
    hex: '#FA92A2',
  },
];

const particlesConfig = {
  fullScreen: {
    enable: false,
  },
  particles: {
    number: {
      value: 0,
    },
    color: {
      value: [...COLOUR_MAP.map((colour) => colour.hex)],
    },
    shape: {
      type: ['circle', 'square'],
    },
    opacity: {
      value: {
        max: 0.8,
        min: 0,
      },
      animation: {
        enable: true,
        speed: 1,
        startValue: 'max',
        destroy: 'min',
      },
    },
    size: {
      value: { min: 2, max: 4 },
    },
    life: {
      duration: {
        sync: true,
        value: 5,
      },
      count: 1,
    },
    move: {
      enable: true,
      gravity: {
        enable: true,
        acceleration: 20,
      },
      speed: {
        min: 25,
        max: 60,
      },
      drift: {
        min: -2,
        max: 2,
      },
      decay: 0.05,
      direction: 'none',
      outModes: {
        default: 'destroy',
        top: 'none',
      },
    },
    rotate: {
      value: {
        min: 0,
        max: 180,
      },
      direction: 'random',
      move: true,
      animation: {
        enable: true,
        speed: 60,
      },
    },
    tilt: {
      direction: 'random',
      enable: true,
      move: true,
      value: {
        min: 0,
        max: 180,
      },
      animation: {
        enable: true,
        speed: 60,
      },
    },
    roll: {
      darken: {
        enable: true,
        value: 25,
      },
      enable: true,
      speed: {
        min: 15,
        max: 25,
      },
    },
    wobble: {
      distance: 30,
      enable: true,
      move: true,
      speed: {
        min: -15,
        max: 15,
      },
    },
  },
  interactivity: {
    detectsOn: 'canvas',
    events: {
      resize: true,
    },
  },
  detectRetina: true,
  //   responsive: [
  //     {
  //       maxWidth: 400,
  //       options: {
  //         particles: {
  //           move: {
  //             speed: 20,
  //             decay: 0.1,
  //           },
  //         },
  //         emitters: [
  //           {
  //             direction: 'top',
  //             rate: {
  //               delay: 0.1,
  //               quantity: 5,
  //             },
  //             position: {
  //               x: 50,
  //               y: 30,
  //             },
  //             size: {
  //               width: 0,
  //               height: 0,
  //             },
  //           },
  //         ],
  //       },
  //     },
  //   ],
  emitters: [
    {
      direction: 'top',
      rate: {
        delay: 0.1,
        quantity: 10,
      },
      position: {
        x: 50,
        y: 100,
      },
      size: {
        width: 0,
        height: 0,
      },
    },
  ],
};

const Join: FC<{
  ctaText?: string;
  ctaSubtext?: string;
  showSlackBtn?: boolean;
}> = ({
  ctaText = 'Create the group gift',
  ctaSubtext = 'Send a gift that will be cherished forever',
  showSlackBtn,
}) => {
  return (
    <Container>
      <Particles options={particlesConfig} />
      <Body>
        <Header>Join the new way of celebrating</Header>
        <Paragraph>{ctaSubtext}</Paragraph>
        {showSlackBtn ? (
          <Actions>
            <GroupGiftLink href={SLACK_INSTALL_LINK}>
              <svg
                style={{ marginRight: 10 }}
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <rect width='24' height='24' rx='6' fill='white' />
                <path
                  d='M6.77079 14.363C6.77079 15.4189 5.91607 16.2485 4.88539 16.2485C3.85471 16.2485 3 15.3938 3 14.363C3 13.3323 3.85471 12.4775 4.88539 12.4775H6.77079V14.363Z'
                  fill='#E01E5A'
                />
                <path
                  d='M7.72656 14.363C7.72656 13.3072 8.58127 12.4775 9.61196 12.4775C10.6426 12.4775 11.4973 13.3323 11.4973 14.363V19.1145C11.4973 20.1703 10.6426 21 9.61196 21C8.58127 21 7.72656 20.1452 7.72656 19.1145V14.363Z'
                  fill='#E01E5A'
                />
                <path
                  d='M9.63735 6.77098C8.58153 6.77098 7.75195 5.91623 7.75195 4.88549C7.75195 3.85476 8.60666 3 9.63735 3C10.668 3 11.5227 3.85476 11.5227 4.88549V6.77098H9.63735Z'
                  fill='#36C5F0'
                />
                <path
                  d='M9.63658 7.72607C10.6924 7.72607 11.522 8.58083 11.522 9.61157C11.522 10.6423 10.6673 11.4971 9.63658 11.4971H4.88539C3.82957 11.4971 3 10.6423 3 9.61157C3 8.58083 3.85471 7.72607 4.88539 7.72607H9.63658Z'
                  fill='#36C5F0'
                />
                <path
                  d='M17.2031 9.63696C17.2031 8.58108 18.0578 7.75146 19.0885 7.75146C20.1443 7.75146 20.9739 8.60622 20.9739 9.63696C20.9739 10.6677 20.1192 11.5224 19.0885 11.5224H17.2031V9.63696Z'
                  fill='#2EB67D'
                />
                <path
                  d='M16.2747 9.63693C16.2747 10.6928 15.42 11.5224 14.3893 11.5224C13.3586 11.5224 12.5039 10.6677 12.5039 9.63693V4.88549C12.5039 3.82962 13.3586 3 14.3893 3C15.42 3 16.2747 3.85476 16.2747 4.88549V9.63693Z'
                  fill='#2EB67D'
                />
                <path
                  d='M14.3639 17.2041C15.4197 17.2041 16.2493 18.0589 16.2493 19.0896C16.2493 20.1455 15.3946 20.9751 14.3639 20.9751C13.3332 20.9751 12.4785 20.1203 12.4785 19.0896V17.2041H14.3639Z'
                  fill='#ECB22E'
                />
                <path
                  d='M14.3639 16.2739C13.3081 16.2739 12.4785 15.4192 12.4785 14.3884C12.4785 13.3577 13.3332 12.5029 14.3639 12.5029H19.1151C20.1709 12.5029 21.0005 13.3577 21.0005 14.3884C21.0005 15.4192 20.1458 16.2739 19.1151 16.2739H14.3639Z'
                  fill='#ECB22E'
                />
                <path
                  d='M6.77079 14.363C6.77079 15.4189 5.91607 16.2485 4.88539 16.2485C3.85471 16.2485 3 15.3938 3 14.363C3 13.3323 3.85471 12.4775 4.88539 12.4775H6.77079V14.363Z'
                  fill='#E01E5A'
                />
                <path
                  d='M7.72656 14.363C7.72656 13.3072 8.58127 12.4775 9.61196 12.4775C10.6426 12.4775 11.4973 13.3323 11.4973 14.363V19.1145C11.4973 20.1703 10.6426 21 9.61196 21C8.58127 21 7.72656 20.1452 7.72656 19.1145V14.363Z'
                  fill='#E01E5A'
                />
                <path
                  d='M9.61196 6.77098C8.55614 6.77098 7.72656 5.91623 7.72656 4.88549C7.72656 3.85476 8.58127 3 9.61196 3C10.6426 3 11.4973 3.85476 11.4973 4.88549V6.77098H9.61196Z'
                  fill='#36C5F0'
                />
                <path
                  d='M9.61144 7.72607C10.6673 7.72607 11.4968 8.58083 11.4968 9.61157C11.4968 10.6423 10.6421 11.4971 9.61144 11.4971H4.88539C3.82957 11.4971 3 10.6423 3 9.61157C3 8.58083 3.85471 7.72607 4.88539 7.72607H9.61144Z'
                  fill='#36C5F0'
                />
                <path
                  d='M17.2031 9.63696C17.2031 8.58108 18.0578 7.75146 19.0885 7.75146C20.1443 7.75146 20.9739 8.60622 20.9739 9.63696C20.9739 10.6677 20.1192 11.5224 19.0885 11.5224H17.2031V9.63696Z'
                  fill='#2EB67D'
                />
                <path
                  d='M16.2493 9.63693C16.2493 10.6928 15.3946 11.5224 14.3639 11.5224C13.3081 11.5224 12.4785 10.6677 12.4785 9.63693V4.88549C12.4785 3.82962 13.3332 3 14.3639 3C15.4197 3 16.2493 3.85476 16.2493 4.88549V9.63693Z'
                  fill='#2EB67D'
                />
                <path
                  d='M14.3639 17.2041C15.4197 17.2041 16.2493 18.0589 16.2493 19.0896C16.2493 20.1455 15.3946 20.9751 14.3639 20.9751C13.3081 20.9751 12.4785 20.1203 12.4785 19.0896V17.2041H14.3639Z'
                  fill='#ECB22E'
                />
                <path
                  d='M14.3639 16.2739C13.3081 16.2739 12.4785 15.4192 12.4785 14.3884C12.4785 13.3577 13.3332 12.5029 14.3639 12.5029H19.1151C20.1709 12.5029 21.0005 13.3577 21.0005 14.3884C21.0005 15.4192 20.1458 16.2739 19.1151 16.2739H14.3639Z'
                  fill='#ECB22E'
                />
              </svg>
              Try illume For Free
            </GroupGiftLink>
          </Actions>
        ) : (
          <JoinButton href={CREATE_CARD_LINK}>{ctaText}</JoinButton>
        )}
      </Body>
    </Container>
  );
};

export default Join;
