import React, { FC } from 'react';
import styled from 'styled-components';
import { TestimonialCarouselV2 as TestimonialsCarouselV2 } from './components/TestimonialsV2';
import { ITestimonial } from '../common/types';
import useFormFactors from '../../helpers/useFormFactors';

const Layout = styled.section`
  display: flex;
  flex-direction: column;
  padding: 82px 0;
  width: 100%;
  justify-content-center;
  z-index: 2;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    padding: 0 56px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    padding: 0 78px;
    padding-left: 0px;
  }
`;

const Heading = styled.p`
  color: ${(props) => props.theme.colors.blackText};
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  margin: 0 0 46px 0;
  text-align: center;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    font-size: 44px;
    line-height: 55px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 64px;
    line-height: 70px;
  }
`;

interface Props {
  testimonials: ITestimonial[];
}
const LoveIllume: FC<Props> = ({ testimonials }) => {
  const { tablet, laptop, laptopAll } = useFormFactors();
  return (
    <Layout>
      <Heading>Why people love illume</Heading>
      <TestimonialsCarouselV2 withNav={false} testimonials={testimonials} />
    </Layout>
  );
};

export default LoveIllume;
