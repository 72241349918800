import React, { FC } from 'react';
import styled from 'styled-components';
import { ITestimonial } from '../../../common/types';

const Container = styled.div`
  margin: 0 10px;
  border-radius: 8px;
  overflow: hidden;
  min-height: 444px;
  display: flex;
  flex-direction: column;
`;

const ImageBackgroundContainer = styled.div<{ url: string }>`
  width: 100%;
  height: 287px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 43.73%, rgba(0, 0, 0, 0.7) 90.52%),
    url(${(props) => props.url});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
`;

const TestimonialContainer = styled.div`
  padding: 16px;
  background-color: #f9f5e8;
  flex-grow: 1;
  p {
    font-size: 20px;
    font-weight: 390;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
  }
`;

const AvatarContainer = styled.div`
  column-gap: 8px;
  display: flex;
  position: absolute;
  bottom: 16px;
  left: 16px;
`;

const Avatar = styled.img`
  max-width: 46px;
  width: 100%;
`;

const TitleAndCityContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  .title {
    font-size: 14px;
    font-weight: 420;
    line-height: 16px;
    color: rgba(229, 166, 25, 1);
  }
  .name-and-address {
    font-size: 18px;
    font-weight: 420;
    line-height: 20px;
    color ${(props) => props.theme.colors.lightPeach2}
  }
`;

interface Props {
  testimonial: ITestimonial;
}
const Slide: FC<Props> = ({ testimonial }) => {
  const { imageLarge, alt, copy, user } = testimonial;

  const address = `${user.city}, ${user.state}`;

  return (
    <Container>
      <ImageBackgroundContainer url={imageLarge} title={alt} aria-label={alt} draggable='false'>
        <AvatarContainer>
          <Avatar src={user.image} alt={user.firstName} />
          <TitleAndCityContainer>
            <h2 className='title'>{user.title}</h2>
            <h3 className='name-and-address'>
              {user.firstName} - {address}
            </h3>
          </TitleAndCityContainer>
        </AvatarContainer>
      </ImageBackgroundContainer>
      <TestimonialContainer>
        <p>{copy}</p>
      </TestimonialContainer>
    </Container>
  );
};

export default Slide;
