import React, { FC } from 'react';
import styled from 'styled-components';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './styles.css';

import Slide from './Slide';

import backArrow from '../../../../icons/carousel-back.svg';
import forwardArrow from '../../../../icons/carousel-forward.svg';

import { ITestimonial } from '../../../common/types';

const Frame = styled.div`
  padding: 0;
  position: relative;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    padding: 0;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    padding: 0;
    width: 100%;
  }
`;

const ButtonGroupContainer = styled.div<{ spacing: number }>`
  display: flex;
  position: absolute;
  right: 0;
  margin-top: 22px;
  column-gap: ${(props) => (props.spacing ? `${props.spacing}px` : '0')};
`;

const CarouselButton = styled.div`
  display: flex;
  color: ${(props) => props.theme.colors.darkGray};
  cursor: pointer;
  padding: 0 4px;

  img {
    padding: 0 2px;
  }

  p {
    padding: 0 2px;
  }
`;

const ButtonGroup: FC<any> = ({ next, previous, withWording }) => {
  return (
    <ButtonGroupContainer spacing={withWording ? 0 : 16}>
      <CarouselButton onClick={previous}>
        <img alt='' src={backArrow} />
        {withWording && <p>Previous</p>}
      </CarouselButton>
      <CarouselButton onClick={next}>
        {withWording && <p>Next</p>}
        <img alt='' src={forwardArrow} />
      </CarouselButton>
    </ButtonGroupContainer>
  );
};

const carouselSetup = {
  additionalTransfrom: 0,
  arrows: false,
  autoPlay: false,
  autoPlaySpeed: 0,
  centermode: true,
  className: '',
  containerClass: '',
  dotListClass: '',
  draggable: true,
  focusOnSelect: false,
  infinite: true,
  itemClass: '',
  keyBoardControl: true,
  minimumTouchDrag: 100,
  partialVisible: true,
  renderButtonGroupOutside: true,
  renderDotsOutside: false,
  responsive: {
    desktopLarge: {
      breakpoint: { max: 1920, min: 1440 },
      items: 4,
      partialVisibilityGutter: 40,
    },
    desktop: {
      breakpoint: { max: 1440, min: 1024 },
      items: 3,
      partialVisibilityGutter: 40,
    },
    desktopSmall: {
      breakpoint: { max: 1024, min: 728 },
      items: 3,
      partialVisibilityGutter: 0,
    },
    tablet: {
      breakpoint: { max: 728, min: 464 },
      items: 2,
      partialVisibilityGutter: 0,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 0,
    },
  },
  showDots: false,
  shouldResetAutoplay: false,
  sliderClass: '',
  slidesToSlide: 1,
  swipeable: true,
};

interface Props {
  testimonials: ITestimonial[];
  withNav?: boolean;
}

export const TestimonialCarouselV2: FC<Props> = ({ testimonials, withNav = false }) => {
  return (
    <Frame>
      <Carousel
        {...carouselSetup}
        autoPlay={true}
        autoPlaySpeed={1000}
        swipeable={false}
        customButtonGroup={withNav ? <ButtonGroup withWording={false} /> : undefined}
      >
        {testimonials.map((testimony, index: number) => (
          <Slide testimonial={testimony} key={index} />
        ))}
      </Carousel>
    </Frame>
  );
};
